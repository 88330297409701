<template>
  <b-field grouped group-multiline>
    <div class="control" v-for="(selected, index) in userEnsemble" :key="index">
      <b-tag
        size="is-medium"
        attached
        closable
        aria-close-label="Close tag"
        @close="removeInstrument(selected.id)"
        :class="[
          'inst-tag',
          { 'is-woodwind': selected.section === 'woodwinds' },
          { 'is-string': selected.section === 'strings' },
          { 'is-brass': selected.section === 'brass' },
        ]"
      >
        <span v-html="$options.filters.displayInstrumentName(selected)"></span>
      </b-tag>
    </div>
  </b-field>
</template>

<script>
export default {
  computed: {
    userEnsemble() {
      return this.$store.getters.userEnsemble;
    },
  },
  methods: {
    removeInstrument: function (instId) {
      const userEnsemble = this.userEnsemble;
      const foundIndex = userEnsemble.findIndex((obj) => {
        return obj.id === instId;
      });
      if (foundIndex !== -1) {
        userEnsemble.splice(foundIndex, 1);
        const userEnsembleSorted = this.sortInstruments(userEnsemble);
        this.$store.commit('updateUserEnsemble', userEnsembleSorted);
      }
    },
    sortInstruments: function (instruments) {
      return instruments.sort((a, b) => (a.order > b.order ? 1 : -1));
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/styles';

// the label
.tags.inst-tag .tag {
  border-radius: 4px 4px;
  font-weight: 600;
  color: $gray-light;
}
.is-woodwind.tags.inst-tag .tag {
  background-color: $purple;
}
.is-string.tags.inst-tag .tag {
  background-color: $blue;
}
.is-brass.tags.inst-tag .tag {
  background-color: $gold;
}

// the X
.tags.inst-tag .tag.is-delete {
  // use !important to overide the color from the tag
  background-color: $white !important;
  border: solid 1px $gray;
  border-radius: 0px 4px 4px 0px;
  color: $gray-dark;

  &::after {
    width: 2px;
  }

  &::before {
    height: 2px;
  }
}
</style>
