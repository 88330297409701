<template>
  <div class="card-container">
    <div
      class="card"
      v-for="(piece, index) in showingPiecesList"
      :key="index"
      v-on:click="$router.push(`/bwv/${piece.bwv}`)"
    >
      <header class="card-header">
        <p class="card-header-title">
          <a>
            <span class="regular"> Prelude and Fugue </span> <br />
            <span class="large"
              >No. {{ piece.no }} in
              <span v-html="$options.filters.keySymbols(piece.key)"></span
            ></span>
            <span class="regular"> (BWV {{ piece.bwv }})</span> <br />
          </a>
        </p>
      </header>
      <div class="card-content">
        <div class="content">
          <div>
            Prelude:
            <strong>
              {{ piece.prelude.type | numberToEnsembleSize | titleize }}
            </strong>
            | Fugue:
            <strong>{{
              piece.fugue.type | numberToEnsembleSize | titleize
            }}</strong>
          </div>
        </div>
      </div>
      <footer class="card-footer">
        <!-- TODO: Add a Music Notes Icon? -->
        <a
          class="card-action card-footer-item preview"
          v-on:click="showPreview($event, piece.id)"
          >Preview</a
        >
        <b-tooltip
          class="has-text-centered tooltip-flex-wrapper"
          v-bind:class="{ disabled: !allPartsValid(piece, userEnsembleSorted) }"
          @click.native="addToCart($event, piece)"
          :type="
            !allPartsValid(piece, userEnsembleSorted) ? 'is-dark' : 'is-light'
          "
          position="is-bottom"
          multilined
        >
          <span class="card-action card-footer-item">Add to Cart</span>
          <template v-slot:content>
            <p v-if="!allPartsValid(piece, userEnsembleSorted)">
              Not compatible with your selected ensemble.
            </p>
            <div
              class="has-text-left"
              v-else
              v-html="stringifySelectedParts(piece)"
            ></div>
          </template>
        </b-tooltip>
      </footer>
      <footer class="price-container card-footer">
        <span
          class="price card-footer-item"
          v-if="allPartsValid(piece, userEnsembleSorted)"
        >
          <span class="sale-price">
            {{ $options.filters.intToCurrency(getPrice(piece)) }}
          </span>
          <span class="original-price">
            {{ $options.filters.intToCurrency(getOriginalPrice(piece)) }}
          </span>
        </span>
        <span class="price card-footer-item" v-else>Not compatible</span>
      </footer>
    </div>
  </div>
</template>

<script>
import Pricing from '@/mixins/Pricing';
import Parts from '@/mixins/Parts';
// import ScorePreview from '../ScorePreview.vue';
import ScorePreviewIncipit from '../ScorePreviewIncipit.vue';
import $eventHub from '@/components/EventHub';

export default {
  props: {
    book: Number,
    filterSwitch: Boolean,
  },
  mixins: [Pricing, Parts],
  data() {
    return {
      showingPieces: [],
      selectedPieces: [],
    };
  },
  computed: {
    allPieces() {
      const thisBook = this.book;
      return this.$store.getters.pieces.filter(
        (piece) => piece.book === thisBook
      );
    },
    showingPiecesList() {
      return this.allPieces.filter(
        (piece, index) => this.showingPieces[index] !== false
      );
    },
    userEnsemble() {
      return this.$store.getters.userEnsemble;
    },
    userEnsembleSorted() {
      return this.$store.getters.userEnsembleSorted;
    },
  },
  methods: {
    isDisparate: function (piece) {
      return piece.prelude.type !== piece.fugue.type;
    },
    stringifySelectedParts(piece) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const vue = this;
      const { prelude, fugue, both } = this.getFilteredPartsByEnsemble(
        piece,
        this.userEnsembleSorted
      );
      let bothStrings, preludeStrings, fugueStrings, finalStr;
      if (both) {
        //Render the string for both items.
        bothStrings =
          both && both.map((p) => `<li>${vue.stringifyPart(p)}</li>`);
        finalStr = bothStrings
          ? `Prelude & Fugue: <ul>${bothStrings.join('')}</ul>`
          : '';
      } else {
        //Render the prelude and fugue separately.
        preludeStrings =
          prelude && prelude.map((p) => `<li>${vue.stringifyPart(p)}</li>`);
        fugueStrings =
          fugue && fugue.map((p) => `<li>${vue.stringifyPart(p)}</li>`);
        finalStr =
          preludeStrings && fugueStrings
            ? `Prelude: <ul style="padding-bottom: 1rem">${preludeStrings.join(
                ''
              )}</ul> Fugue: <ul>${fugueStrings.join('')}</ul>`
            : '';
      }
      return finalStr;
    },
    addToCart(e, piece) {
      // Card actions shouldn't navigate to the piece page.
      e.stopPropagation();
      if (!this.allPartsValid(piece, this.userEnsembleSorted)) return false;

      const { prelude, fugue, both } = this.getFilteredPartsByEnsemble(
        piece,
        this.userEnsembleSorted
      );
      const selectedParts = both
        ? { prelude: both, fugue: both }
        : { prelude, fugue };

      // Format data for cart state
      const cartPiece = {
        piece,
        selectedParts,
        score: true,
        price: this.calculateCustomPrice(piece, selectedParts),
        originalPrice: this.calculateOriginalCustomPrice(piece, selectedParts),
      };

      this.$store.commit('addToCart', cartPiece);
      this.$buefy.snackbar.open({
        message: 'Piece added to your cart!',
        type: 'is-success',
        position: 'is-top',
        actionText: 'Go to Cart',
        duration: 4000,
        onAction: () => {
          this.$router.push('/build/finalize');
        },
      });

      $eventHub.$emit('cartChange', true);
    },
    showPreview(e, id) {
      // Card actions shouldn't navigate to the piece page.
      e.stopPropagation();
      const piece = this.allPieces.find((piece) => piece.id == id);
      this.$buefy.modal.open({
        component: ScorePreviewIncipit,
        props: {
          piece,
        },
      });
    },
    updateEnsembleFilter(ensemble) {
      // Always reset the filter. Actually perform the filter if we have set ensemble.
      this.resetFilter();
      if (ensemble) {
        this.filterPiecesByEnsemble();
      }
    },
    filterPiecesByEnsemble: function () {
      const ensembleSize = this.userEnsemble.length;
      this.allPieces.forEach((piece, index) => {
        if (
          piece.fugue.type > ensembleSize ||
          piece.prelude.type > ensembleSize ||
          !this.allPartsValid(piece, this.userEnsembleSorted)
        ) {
          this.$set(this.showingPieces, index, false);
        }
      });
    },
    resetFilter() {
      this.showingPieces.forEach((piece, index) => {
        this.$set(this.showingPieces, index, true);
      });
    },
    getPrice(piece) {
      const { prelude, fugue, both } = this.getFilteredPartsByEnsemble(
        piece,
        this.userEnsembleSorted
      );

      const selectedParts = both
        ? { prelude: both, fugue: both }
        : { prelude, fugue };

      const { total } = this.calculateCustomPrice(piece, selectedParts);
      return total;
    },
    getOriginalPrice(piece) {
      const { prelude, fugue, both } = this.getFilteredPartsByEnsemble(
        piece,
        this.userEnsembleSorted
      );

      const selectedParts = both
        ? { prelude: both, fugue: both }
        : { prelude, fugue };

      const { total } = this.calculateOriginalCustomPrice(piece, selectedParts);
      return total;
    },
  },

  watch: {
    userEnsemble: function (ensemble) {
      // Emit event to signify the switch toggle and then we can update the piece list.
      this.$emit('update:filterSwitch', ensemble.length > 0);
      this.updateEnsembleFilter(ensemble.length > 0);
    },
    filterSwitch: function (val) {
      // Simply reset the filter if toggled off, or reset AND go through the piece list if toggled on.
      this.updateEnsembleFilter(val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles';

.tooltip-flex-wrapper {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;

  &.disabled .card-action {
    background: $gray;
    cursor: not-allowed;
    text-decoration: line-through;
  }
}

.card-footer-item.preview {
  /* Offset tooltip flex padding */
  padding: 0;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .card {
    width: 314px;
    margin: 12px;

    .card-header-title {
      font-size: 18px;
    }
  }
}

.regular {
  font-weight: 400;
  font-size: 14px;
}

.regular,
.large {
  color: $primary;
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.pricing {
  width: 180px;

  table {
    tr.base-price td,
    tr.extra-parts td {
      border: none;
    }
    tr.total td {
      border-bottom: none;
      border-top: 1px solid;
    }
    td {
      padding: 4px;
    }
  }
}

.card {
  cursor: pointer;
  overflow: visible;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.22) 0px 30px 60px -10px,
      rgba(0, 0, 0, 0.25) 0px 18px 36px -18px;
  }

  .card-content {
    padding: 1.5rem 1rem;
  }
}

.price-container {
  background: $gray-light;
}

// .original-price {
//   text-decoration: line-through 2px;
//   margin-inline: 5px;
// }
.sale-price {
  color: $purple;
  margin-inline: 5px;
}

.card-action {
  background: $primary;
  color: $white;
  font-weight: bolder;

  &:hover {
    color: $white !important;
    background-color: $gold;
  }
}

.price {
  font-weight: bolder;
}
</style>
