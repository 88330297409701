<template>
  <section>
    <build-ensemble-picker ref="picker" />
    <div class="flex-wrapper"><build-ensemble-tags /></div>
    <p class="paragraph">
      Your selected instruments are sorted into each size of ensemble. If a size
      is disabled, the instruments currently selected are not compatible with
      that size.
    </p>

    <div class="flex-wrapper">
      <div class="ensemble-sorted">
        <div
          v-for="(ensembleStatus, ensembleNumber) in ensembleReady"
          :key="ensembleNumber"
          :id="`${$options.filters.titleize(
            $options.filters.numberToEnsembleSize(parseInt(ensembleNumber))
          )}s`"
        >
          <h1 :class="['title', { 'is-disabled': !ensembleStatus }]">
            {{
              `${$options.filters.titleize(
                $options.filters.numberToEnsembleSize(parseInt(ensembleNumber))
              )}s`
            }}
          </h1>
          <ul v-if="ensembleStatus">
            <li
              v-for="(partInsts, partNumber) in selectedSorter[ensembleNumber]"
              :key="partNumber"
            >
              <strong>Part {{ partNumber | numberToRoman }}</strong>
              <ul>
                <li v-for="(inst, i) in partInsts" :key="i">
                  <span
                    v-html="$options.filters.displayInstrumentName(inst)"
                  ></span>
                </li>
              </ul>
            </li>
          </ul>
          <p v-else-if="userEnsemble.length !== 0">
            This ensemble is not available for selected instruments.
          </p>
        </div>
      </div>
    </div>
    <!-- Reset Ensemble Modal -->
    <b-button
      type="is-danger is-light"
      outlined
      :disabled="userEnsemble.length === 0"
      @click="alertDelete"
      class="level-left reset-ensemble"
    >
      Reset Ensemble
    </b-button>
  </section>
</template>

<script>
import BuildEnsemblePicker from '@/components/BuildEnsemble/BuildEnsemblePicker';
import BuildEnsembleTags from '@/components/BuildEnsemble/BuildEnsembleTags';
import Config from '@/config';
const { isScreenSm } = Config;

export default {
  name: 'BuildEnsemble',
  components: {
    BuildEnsemblePicker,
    BuildEnsembleTags,
  },
  created() {
    window.addEventListener('resize', this.updateIsMobile);
    this.updateIsMobile();
  },
  computed: {
    //The default structure of how parts are distributed in the pieces.
    parts() {
      return this.$store.getters.parts;
    },
    userEnsemble() {
      return this.$store.getters.userEnsemble;
    },
    ensembleReady() {
      const storedReady = this.$store.getters.ensembleReady;
      const computedReady = {};
      for (const size in storedReady) {
        // make sure duo doesn't light up without trio
        if (size === '2') {
          computedReady[2] = storedReady[3];
        } else {
          computedReady[size] = storedReady[size];
        }
      }
      return computedReady;
    },
    selectedSorter() {
      return this.$store.getters.userEnsembleSorted;
    },
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    updateIsMobile() {
      this.isMobile = isScreenSm(window.innerWidth);
    },
    alertDelete: function () {
      this.$buefy.dialog.confirm({
        title: 'Confirm Reset',
        message: 'Are you sure you want to reset your ensemble?',
        confirmText: 'Reset Ensemble',
        type: 'is-danger',
        hasIcon: true,
        // reach into the picker and reset it. Seems messy but works
        onConfirm: () => this.$refs.picker.resetEnsemble(),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/sass/styles.scss';

.flex-wrapper {
  display: flex;
  justify-content: center;
  margin: $gap auto;
}

.ensemble-sorted {
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 8px;

  div {
    max-width: 200px;
    margin: 8px;
  }
}

h1.is-disabled {
  color: $gray;
  border-bottom: $gray solid 7px;
}

.reset-ensemble {
  margin: $gap auto;
  @include touch {
    justify-content: center;
    width: 100%;
  }
}

.paragraph {
  text-align: center;
  @include touch {
    text-align: inherit;
  }
}
</style>
