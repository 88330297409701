<template>
  <div class="section" id="pieces-list">
    <b-field class="filter">
      <b-switch v-model="filterSwitch" :disabled="userEnsemble.length === 0"
        >Filter Based On Your Ensemble</b-switch
      >
    </b-field>
    <b-tabs position="is-centered" size="is-large" expanded>
      <b-tab-item label="Book I">
        <piece-card-list
          :book="1"
          v-bind:filterSwitch="filterSwitch"
          @update:filterSwitch="filterSwitch = $event"
        />
      </b-tab-item>
      <b-tab-item label="Book II">
        <piece-card-list
          :book="2"
          v-bind:filterSwitch="filterSwitch"
          @update:filterSwitch="filterSwitch = $event"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import PieceCardList from '@/components/Piece/PieceCardList';
export default {
  name: 'Pieces',
  components: {
    PieceCardList,
  },
  data() {
    return {
      filterSwitch: false,
    };
  },
  mounted() {
    // Persist state of filter switch if value exists in client local storage
    if (
      this.userEnsemble.length > 0 &&
      localStorage.filterSwitchOn &&
      JSON.parse(localStorage.filterSwitchOn)
    ) {
      this.filterSwitch = true;
    }
  },
  computed: {
    userEnsemble() {
      return this.$store.getters.userEnsemble;
    },
  },
  watch: {
    filterSwitch(newValue) {
      localStorage.filterSwitchOn = newValue;
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/styles';
.filter {
  padding-bottom: $gap;
}
@include mobile {
  #pieces-list {
    padding: 0;
  }
  .b-tabs .tab-content {
    padding: 0;
  }
}
</style>
