<template>
  <div class="level">
    <div class="level-item start">
      <b-field class="ensemble-input" label="Pick a Common Ensemble">
        <b-select
          placeholder="Select an ensemble"
          icon="user-friends"
          icon-pack="fas"
          @input="selectEnsemble"
          id="common-ensemble"
          v-model="userEnsembleId"
          size="is-medium"
          expanded
        >
          <option v-for="ens in commonEnsembles" :value="ens.id" :key="ens.id">
            {{ ens.label }}
          </option>
        </b-select>
      </b-field>
    </div>

    <div class="level-item separator">or</div>

    <div class="level-item end">
      <b-field class="ensemble-input" label="Select Your Own Instruments">
        <b-autocomplete
          v-model="name"
          :data="compareSearchTerm"
          placeholder="e.g. Flute, Clarinet, Violin"
          icon="search"
          @select="(option) => saveInstrument(option)"
          clear-on-select
          field="title"
          size="is-medium"
          expanded
          open-on-focus
          group-field="type"
          group-options="items"
        >
          <template slot="empty">No results found</template>
          <template slot-scope="props">
            {{ props.option | displayInstrumentName(false) }}
          </template>
        </b-autocomplete>
      </b-field>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    commonEnsembles() {
      return this.$store.getters.ensembles;
    },
    userEnsemble() {
      return this.$store.getters.userEnsemble;
    },
    userEnsembleId: {
      get() {
        return this.$store.getters.userEnsembleId;
      },
      set(value) {
        this.$store.commit('updateUserEnsembleId', value);
      },
    },
    instruments: function () {
      //Add the order parameter. Use traditional for loop to enforce order?
      const instruments = this.$store.getters.instruments;
      for (let i = 0; i < instruments.length; i++) {
        instruments[i].order = i;
      }
      return instruments;
    },
    groupedInstruments() {
      const vue = this;
      const instruments = this.instruments;
      const sections = [];
      instruments.forEach((instrument) => {
        if (
          !sections.some(
            (group) =>
              group.type ===
              vue.$options.filters.capitalizeFirstLetter(instrument.section)
          )
        ) {
          sections.push({
            type: vue.$options.filters.capitalizeFirstLetter(
              instrument.section
            ),
            items: [],
          });
        }

        sections
          .find(
            (group) =>
              group.type ===
              vue.$options.filters.capitalizeFirstLetter(instrument.section)
          )
          .items.push(instrument);
      });

      return sections;
    },
    compareSearchTerm() {
      const newData = [];
      const searchTerm = this.name;
      this.groupedInstruments.forEach((element) => {
        const items = element.items.filter(
          (item) =>
            item.label.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
        );
        if (items.length) {
          newData.push({ type: element.type, items });
        }
      });

      return newData;
    },
  },
  data() {
    return {
      filteredDataArray: [],
      name: '',
    };
  },
  methods: {
    selectEnsemble: function (ensembleId) {
      this.resetEnsemble(false);

      const instruments = this.instruments;
      const ensInsts =
        this.commonEnsembles.find((e) => e.id === ensembleId)?.instruments ??
        []; // ensemble.instruments;

      const userEnsemble = this.userEnsemble;

      ensInsts.forEach(function (instObj) {
        const instrument = instruments.find(function (inst) {
          return inst.id === instObj.instrument;
        });
        userEnsemble.push(instrument);
      });

      const userEnsembleSorted = this.sortInstruments(userEnsemble);
      this.$store.commit('updateUserEnsemble', userEnsembleSorted);
      this.userEnsembleId = ensembleId;
    },

    resetEnsemble: function (toast = true) {
      this.$store.commit('updateUserEnsembleId', null);
      this.$store.commit('resetUserEnsemble');
      this.$store.commit('resetUserEnsembleSorted');
      this.$store.commit('resetEnsembleReady');
      this.userEnsembleId = null;
      if (toast) {
        this.$buefy.toast.open('Ensemble Reset!');
      }
    },

    saveInstrument: function (instrument) {
      this.userEnsemble.push(instrument);
      const userEnsembleSorted = this.sortInstruments(this.userEnsemble);
      this.$store.commit('updateUserEnsemble', userEnsembleSorted);
      // If custom ensemble then we reset the canonical ensemble selection
      this.userEnsembleId = null;
    },

    sortInstruments: function (instruments) {
      return instruments.sort((a, b) => (a.order > b.order ? 1 : -1));
    },
  },
};
</script>

<style lang="scss">
@import '@/sass/styles';

.ensemble-input {
  select {
    background-color: $gray;
    color: $gray-light;
  }
  &:after {
    border-color: $gray-light !important;
  }

  option {
    background-color: $background;
    font-family: $family-primary !important;
  }
}
</style>

<style lang="scss" scoped>
@import '@/sass/styles';
.start {
  justify-content: flex-start;
}
.end {
  justify-content: flex-end;
}
@include mobile {
  .separator,
  .end {
    justify-content: flex-start;
    width: 100%;
  }
}
.level {
  max-width: $tablet;
  margin: auto;

  @include mobile {
    max-width: none;
    .ensemble-input {
      flex-basis: 100%;
    }
  }
}
.level .separator {
  font-size: 120%;
}
</style>
