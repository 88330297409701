<template>
  <Wizard :steps="steps">
    <template v-slot:1>
      <build-ensemble />
    </template>
    <template v-slot:2>
      <Pieces />
    </template>
    <template v-slot:3>
      <Checkout />
    </template>
  </Wizard>
</template>

<script>
import Wizard from '@/components/Wizard';
import BuildEnsemble from '@/components/BuildEnsemble/BuildEnsemble';
import Pieces from '@/components/Pieces';
import Checkout from '@/components/Checkout/Checkout';

export default {
  name: 'Build',
  components: {
    Wizard,
    BuildEnsemble,
    Pieces,
    Checkout,
  },
  data() {
    return {
      steps: [
        {
          index: 1,
          route: this.$router.resolve({ name: 'Choose Ensemble' }).route,
          title: {
            style: 'h1',
            html: 'Build Your <span class="super-bold">Ensemble</span>',
            plain: 'Build Your Ensemble',
          },
        },
        {
          index: 2,
          route: this.$router.resolve({ name: 'Choose Pieces' }).route,
          title: {
            style: 'h1',
            html: 'Choose <span class="super-bold">Your Piece(s)</span>',
            plain: 'Choose Your Piece(s)',
          },
        },
        {
          index: 3,
          route: this.$router.resolve({ name: 'Finalize' }).route,
          title: {
            style: 'h1',
            html: 'Finalize Your <span class="super-bold">Configuration</span>',
            plain: 'Finalize Your Configuration',
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
